import { NextCookieProvider } from 'next-universal-cookie';
import { SnackbarProvider } from 'notistack';
import AuthProvider from "../lib/providers/AuthProvider";
import '../styles/globals.css';


function MyApp({ Component, pageProps }) {
  return (
    <NextCookieProvider cookie={pageProps.cookie}>
      <AuthProvider>
        <SnackbarProvider>
          <Component {...pageProps} />
        </SnackbarProvider>
      </AuthProvider>
    </NextCookieProvider>
  )
}

export default MyApp
